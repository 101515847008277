import React, { Fragment, } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import UserDispenser from '../User/UserDispenser';
import NoSSR from '../NoSSR/NoSSR';
import Footer from './Footer';
import IconMarkerLogo from '../Icon/icons/IconMarkerLogo';
import Scripts from '../Scripts/Scripts';

// ///////////////////////////////////////////////////////////////////
import FirstImpressionPlaceHolder from '../Paragraph/FirstImpressionPlaceholder';
import WebViewExclude from '../WebViewExclude/WebViewExclude';
// import OutbrainPlaceholder from '../Paragraph/OutbrainPlaceholder';

const AccessListByIp = dynamic(() => import('../Scripts/AccessListByIp'), {
  ssr: false,
  loading: () => null,
});
const FirstImpression = dynamic(() => import('../Scripts/FirstImpression'), {
  ssr: false,
  loading: () => null,
});
const CrazyEgg = dynamic(() => import('../Scripts/CrazyEgg'), { ssr: false, loading: () => null, });
const OutbrainTVR = dynamic(() => import('../Scripts/OutbrainTVR'), {
  ssr: false,
  loading: () => null,
});
const OutbrainObct = dynamic(() => import('../Scripts/OutbrainObct'), {
  ssr: false,
  loading: () => null,
});
const Outbrain30Sec = dynamic(() => import('../Scripts/OutbrainObct').then(mod => mod.Outbrain30Sec), {
  ssr: false,
  loading: () => null,
});
const ChartBeat = dynamic(() => import('../Scripts/ChartBeat'), {
  ssr: false,
  loading: () => null,
});
const InitPixelFallbackImg = dynamic(() => import('../Scripts/InitPixelFallbackImg'), {
  ssr: false,
  loading: () => null,
});
const GemiusIrHomePage = dynamic(() => import('../Scripts/GemiusIrHomePage'), {
  ssr: false,
  loading: () => null,
});
const LogoSchemaGoogle = dynamic(() => import('../Scripts/LogoSchemaGoogle'), {
  ssr: false,
  loading: () => null,
});
const PushWoosh = dynamic(() => import('../Scripts/PushWoosh/PushWoosh'), {
  ssr: false,
  loading: () => null,
});
const FundingChoices = dynamic(() => import('../Scripts/FundingChoices'), {
  ssr: false,
  loading: () => null,
});
const FacebookPixel = dynamic(() => import('../Scripts/FacebookPixel'), {
  ssr: false,
  loading: () => null,
});
const TwitterPixel = dynamic(() => import('../Scripts/TwitterPixel'), {
  ssr: false,
  loading: () => null,
});
const OutbrainPixel = dynamic(() => import('../Scripts/OutbrainPixel'), {
  ssr: false,
  loading: () => null,
});
const OutbrainPixelContent = dynamic(() => import('../Scripts/OutbrainPixelContent'), {
  ssr: false,
  loading: () => null,
});
const GoogleAdsPixel = dynamic(() => import('../Scripts/GoogleAdsPixel'), {
  ssr: false,
  loading: () => null,
});
const GoogleTagsManager = dynamic(() => import('../Scripts/GoogleTagsManager'), {
  ssr: false,
  loading: () => null,
});
// TODO: remove after prod check, meanwhile all pages except wordle section page should have basic configuration
// const GaEvents = dynamic(() => import('../Scripts/GaEvents'), {
//   ssr: false,
//   loading: () => null,
// });
const Taboola = dynamic(() => import('../Scripts/Taboola'), {
  ssr: false,
  loading: () => null,
});
const TaboolaPixelContent = dynamic(() => import('../Scripts/TaboolaPixelContent'), {
  ssr: false,
  loading: () => null,
});
const LinkedinPixel = dynamic(() => import('../Scripts/LinkedinPixel'), {
  ssr: false,
  loading: () => null,
});
const IDX = dynamic(() => import('../Scripts/IDX'), {
  ssr: false,
  loading: () => null,
});
const IDX_2023 = dynamic(() => import('../Scripts/IDX_2023'), {
  ssr: false,
  loading: () => null,
});
const GoogleTagLabels = dynamic(() => import('../Scripts/GoogleTagLabels'), {
  ssr: false,
  loading: () => null,
});
const GoogleTagLabelsNoScripts = dynamic(() => import('../Scripts/GoogleTagLabelsNoScripts'), {
  ssr: true,
  loading: () => null,
});
const GeoEdge = dynamic(() => import('../Scripts/GeoEdge'), {
  ssr: true,
  loading: () => null,
});
const CookieConsent = dynamic(() => import('../Scripts/CookieConsent'), {
  ssr: true,
  loading: () => null,
});
const Kilkaya = dynamic(() => import('../Scripts/Kilkaya'), {
  ssr: false,
  loading: () => null,
});

TmFooter.propTypes = {
  contentId: PropTypes.string.isRequired,
  shouldRenderScripts: PropTypes.bool,
};

TmFooter.defaultProps = {
  shouldRenderScripts: false,
};

function TmFooter({ contentId, shouldRenderScripts, }) {
  return (
    <Fragment>
      <WebViewExclude>
        <FirstImpressionPlaceHolder />
        <Footer contentId={contentId} logo={IconMarkerLogo} logoAltText="TheMarker" />
        <Scripts scriptTag="pushWoosh">
          <PushWoosh site="tm" />
        </Scripts>
        <Scripts scriptTag="accessListByIp">
          <AccessListByIp />
        </Scripts>
        <Scripts scriptTag="crazyEgg">
          <CrazyEgg shouldRender={shouldRenderScripts} />
        </Scripts>
      </WebViewExclude>
      <Scripts scriptTag="outbrainTVR">
        <OutbrainTVR />
      </Scripts>
      <Scripts scriptTag="chartBeat">
        <ChartBeat shouldRender={shouldRenderScripts} />
      </Scripts>
      <Scripts scriptTag="kilkaya">
        <Kilkaya />
      </Scripts>
      <WebViewExclude>
        <Scripts scriptTag="firstImpression">
          <FirstImpression siteId="5338" />
        </Scripts>
        <FirstImpressionPlaceHolder />
      </WebViewExclude>
      <NoSSR>
        <UserDispenser
          render={({ user, }) => (user ? (
            <>
              <Scripts scriptTag="outbrainObct">
                <OutbrainObct isPayingUser={user.type === 'paying'} site="tm" />
              </Scripts>
              <WebViewExclude>
                <Scripts scriptTag="FundingChoices">
                  <FundingChoices isSubscriber={user.type === 'paying'} />
                </Scripts>
              </WebViewExclude>
            </>
          ) : null)
          }
        />
      </NoSSR>
      <Scripts scriptTag="outbrain30sec">
        <Outbrain30Sec site="tm" />
      </Scripts>
      {/* <OutbrainPlaceholder /> */}
      <WebViewExclude>
        <Scripts scriptTag="gemiusIr">
          <GemiusIrHomePage />
        </Scripts>
        <Scripts scriptTag="logoSchemaGoogle">
          <LogoSchemaGoogle />
        </Scripts>
        <Scripts scriptTag="pixel">
          <InitPixelFallbackImg site="tm" />
        </Scripts>
        <Scripts scriptTag="taboolaPixel">
          <Taboola />
        </Scripts>
        <Scripts scriptTag="taboolaPixelContent">
          <TaboolaPixelContent />
        </Scripts>
        <Scripts scriptTag="linkedinPixel">
          <LinkedinPixel />
        </Scripts>
        <Scripts scriptTag="outbrainPixelContent">
          <OutbrainPixelContent />
        </Scripts>
        <Scripts scriptTag="googleAdsPixel">
          <GoogleAdsPixel />
        </Scripts>
        <Scripts scriptTag="googleTagsManager">
          <GoogleTagsManager />
        </Scripts>
        {/* <GaEvents /> */}
        <Scripts scriptTag="facebookPixel">
          <FacebookPixel />
        </Scripts>
        <Scripts scriptTag="twitterPixel">
          <TwitterPixel />
        </Scripts>
        <Scripts scriptTag="idx">
          <IDX />
        </Scripts>
      </WebViewExclude>
      <Scripts scriptTag="geoEdge">
        <GeoEdge />
      </Scripts>
      <Scripts scriptTag="idx2023">
        <IDX_2023 />
      </Scripts>
      <Scripts scriptTag="outbrianPixel">
        <OutbrainPixel />
      </Scripts>
      <Scripts scriptTag="googleGtag">
        <GoogleTagLabels />
        <GoogleTagLabelsNoScripts />
      </Scripts>
      <Scripts scriptTag="cookieConsent">
        <CookieConsent />
      </Scripts>
    </Fragment>
  );
}

export default TmFooter;
